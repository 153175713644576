import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import axios from './axios';
import './styles/fonts.css'; // 引入字体样式文件

// 加载字体
loadFonts();

// 创建全局对象并挂载到 Vue 原型上
const globalObject = {
    typeId: null,
    id: null,
    index: null,
    audio: null,
    image:null
  };

const globalUrl = 'https://suno.aisunosong.com/musicPlayer';

const  genreMap = {
  "男声": "Male Vocal",
  "中国节奏":"Chinese rhythms",
  "中国京剧":"china Peking Opera",
  "中国民歌":"china folk",
  "女声": "Female Vocal",
  "嘻哈": "Hip Hop",
  "R&B": "R&B/Soul",
  "古风音乐": "Traditional/Chinese Style",
  "后摇": "Post-Rock",
  "古典音乐": "Classical Music",
  "爵士乐": "Jazz",
  "摇滚乐": "Rock",
  "流行音乐": "Pop",
  "说唱音乐": "Hip Hop/Rap",
  "电子舞曲": "Electronic Dance Music (EDM)",
  "乡村音乐": "Country",
  "民谣音乐": "Folk",
  "蓝调音乐": "Blues",
  "灵魂乐": "Soul",
  "重金属音乐": "Heavy Metal",
  "朋克摇滚": "Punk Rock",
  "放克音乐": "Funk",
  "雷鬼音乐": "Reggae",
  "世界音乐": "World Music",
  "另类摇滚": "Alternative Rock",
  "独立音乐": "Indie",
  "硬摇滚": "Hard Rock",
  "新世纪音乐": "New Age",
  "爵士摇滚": "Jazz Rock",
  "进步摇滚": "Progressive Rock",
  "实验音乐": "Experimental",
  "电子音乐": "Electronic",
  "工业音乐": "Industrial",
  "抒情摇滚": "Soft Rock",
  "流行朋克": "Pop Punk",
  "芝加哥蓝调": "Chicago Blues",
  "德尔塔蓝调": "Delta Blues",
  "电子爵士": "Nu Jazz/Electro Jazz",
  "环境音乐": "Ambient",
  "民族音乐": "Ethnic/World",
  "拉丁音乐": "Latin",
  "韩国流行音乐": "K-POP",
  "日本流行音乐": "J-POP",
  "歌剧": "Opera",
  "室内乐": "Chamber Music",
  "D&B（鼓打贝斯）": "Drum and Bass",
  "古巴音乐": "Cuban Music",
  "博萨诺瓦": "Bossa Nova",
  "古代音乐": "Early Music",
  "庞克电子": "Electro Punk",
  "嘻游": "Crunk",
  "格莱美风格": "Grammy Style (not a genre but associated with varying styles recognized at the Grammy Awards)",
  "黑金属": "Black Metal",
  "死亡金属": "Death Metal",
  "民谣金属": "Folk Metal",
  "哥特摇滚": "Gothic Rock",
  "实验摇滚": "Experimental Rock",
  "数码硬核": "Digital Hardcore",
  "电子民谣": "Electroacoustic Folk",
  "后朋克": "Post-Punk",
  "新浪潮": "New Wave",
  "情感硬核": "Emo (Emotional Hardcore)",
  "斯卡音乐": "Ska",
  "古典电影音乐": "Classical Cinematic",
  "中世纪电影音乐": "Medieval Cinematic",
  "乡村乐": "Country",
  "蓝调": "Blues",
  "抒情歌": "Ballad",
  "歌剧": "Opera",
  "雷鬼乐": "Reggae",
  "弗拉门戈": "Flamenco",
  "科技舞曲": "Techno",
  "新世纪音乐": "New Age",
  "节奏布鲁斯": "R&B",
  "可跳舞的": "Danceable",
  "草莓乐": "Bluegrass",
  "迪斯科": "Disco",
  "切分节奏": "Syncopated",
  "民谣": "Folk",
  "悠扬": "Groovy",
  "舞曲": "Dance",
  "微醺": "Tipsy",
  "阿弗罗-古巴": "Afro-Cuban",
  "黑暗": "Dark",
  "流行舞曲": "Dance Pop",
  "末日": "Doom",
  "配音步": "Dubstep",
  "戏剧性": "Dramatic",
  "迪斯科放克": "Disco Funk",
  "阴险": "Sinister",
  "电子舞曲": "EDM",
  "电子": "Electronic",
  "艺术": "Art",
  "高能量": "High-NRG",
  "新流行": "Nu",
  "洁室音乐": "House",
  "进步": "Progressive",
  "迷幻舞曲": "Trance",
  "强硬": "Hard",
  "缓拍": "Downtempo",
  "激进": "Aggressive",
  "热门曲目": "Banger",
  "鼓与贝斯": "Drum'n'bass",
  "力量": "Power",
  "体育场": "Stadium",
  "重踏": "Stomp",
  "智能舞曲": "IDM",
  "抒情的": "Lyrical",
  "合成流行": "Synthpop",
  "百老汇": "Broadway",
  "合成波": "Synthwave",
  "歌舞表演": "Cabaret",
  "歌剧式的": "Operatic",
  "爵士/灵魂": "Jazz/Soul",
  "讲故事": "Storytelling",
  "比博普": "Bebop",
  "酒吧歌手": "Lounge",
  "陷阱音乐": "Trap",
  "福音": "Gospel",
  "戏剧性的": "Theatrical",
  "吟游诗人": "Troubadour",
  "拉丁爵士": "Latin Jazz",
  "拉斯维加斯风格": "Vegas",
  "节奏蓝调": "RnB",
  "神奇": "Magical",
  "虚幻": "Ethereal",
  "雄伟": "Majestic",
  "波萨诺瓦": "Bossa Nova",
  "神秘": "Mysterious",
  "简约": "Minimal",
  "弗约罗": "Forró",
  "萨尔萨舞": "Salsa",
  "电影": "Cinematic",
  "探戈": "Tango",
  "缓慢": "Slow",
  "稀疏": "Sparse",
  "舞厅": "Dancehall",
  "派对": "Party",
  "配音": "Dub",
  "华丽": "Glam",
  "闪耀": "Glitter",
  "雷盖顿": "Reggaeton",
  "非洲节奏": "Afrobeat",
  "活力爆发": "Grooveout",
  "金属": "Metal",
  "柔和": "Soft",
  "死亡核": "Deathcore",
  "卧室": "Bedroom",
  "轻松浪潮": "Chillwave",
  "节日重金属": "Festive Heavy Metal",
  "亲密": "Intimate",
  "奇怪": "Weird",
  "力量金属": "Power Metal",
  "嘉年华": "Carnival",
  "鬼屋": "Haunted",
  "流行": "Popular",
  "随机": "Random",
  "音乐盒": "Musicbox",
  "空洞": "Hollow",
  "流行摇滚": "Pop Rock",
  "世界/民族": "World/Ethnic",
  "韩流音乐": "Kpop",
  "日流音乐": "Jpop",
  "摇滚": "Rock",
  "经典摇滚": "Classic Rock",
  "布鲁斯摇滚": "Blues Rock",
  "情绪": "Emo",
  "华丽摇滚": "Glam Rock",
  "独立音乐": "Indie",
  "工业摇滚": "Industrial Rock",
  "朋克摇滚": "Punk",
  "滑板摇滚": "Skate Rock",
  "滑板核": "Skatecore",
  "城市音乐": "Urban",
  "放克": "Funk",
  "嘻哈": "HipHop",
  "酸音乐": "Phonk",
  "说唱": "Rap"
};

const genreMapReversed = {
  "china Peking Opera":"中国京剧",
  "Chinese rhythms":"中国节奏",
  "china folk":"中国民歌",
  "Male Vocal": "男声",
  "Female Vocal": "女声",
  "Hip Hop": "嘻哈",
  "R&B/Soul": "R&B",
  "Traditional/Chinese Style": "古风音乐",
  "Post-Rock": "后摇",
  "Classical Music": "古典音乐",
  "Jazz": "爵士乐",
  "Rock": "摇滚乐",
  "Pop": "流行音乐",
  "Hip Hop/Rap": "说唱音乐",
  "Electronic Dance Music (EDM)": "电子舞曲",
  "Country": "乡村音乐",
  "Folk": "民谣音乐",
  "Blues": "蓝调音乐",
  "Soul": "灵魂乐",
  "Heavy Metal": "重金属音乐",
  "Punk Rock": "朋克摇滚",
  "Funk": "放克音乐",
  "Reggae": "雷鬼音乐",
  "World Music": "世界音乐",
  "Alternative Rock": "另类摇滚",
  "Indie": "独立音乐",
  "Hard Rock": "硬摇滚",
  "New Age": "新世纪音乐",
  "Jazz Rock": "爵士摇滚",
  "Progressive Rock": "进步摇滚",
  "Experimental": "实验音乐",
  "Electronic": "电子音乐",
  "Industrial": "工业音乐",
  "Soft Rock": "抒情摇滚",
  "Pop Punk": "流行朋克",
  "Chicago Blues": "芝加哥蓝调",
  "Delta Blues": "德尔塔蓝调",
  "Nu Jazz/Electro Jazz": "电子爵士",
  "Ambient": "环境音乐",
  "Ethnic/World": "民族音乐",
  "Latin": "拉丁音乐",
  "K-POP": "韩国流行音乐",
  "J-POP": "日本流行音乐",
  "Opera": "歌剧",
  "Chamber Music": "室内乐",
  "Drum and Bass": "D&B（鼓打贝斯）",
  "Cuban Music": "古巴音乐",
  "Bossa Nova": "博萨诺瓦",
  "Early Music": "古代音乐",
  "Electro Punk": "庞克电子",
  "Crunk": "嘻游",
  "Grammy Style (not a genre but associated with varying styles recognized at the Grammy Awards)": "格莱美风格",
  "Black Metal": "黑金属",
  "Death Metal": "死亡金属",
  "Folk Metal": "民谣金属",
  "Gothic Rock": "哥特摇滚",
  "Experimental Rock": "实验摇滚",
  "Digital Hardcore": "数码硬核",
  "Electroacoustic Folk": "电子民谣",
  "Post-Punk": "后朋克",
  "New Wave": "新浪潮",
  "Emo (Emotional Hardcore)": "情感硬核",
  "Ska": "斯卡音乐",
  "Classical Cinematic": "古典电影音乐",
  "Medieval Cinematic": "中世纪电影音乐",
  "Country": "乡村乐",
  "Blues": "蓝调",
  "Ballad": "抒情歌",
  "Opera": "歌剧",
  "Reggae": "雷鬼乐",
  "Flamenco": "弗拉门戈",
  "Techno": "科技舞曲",
  "New Age": "新世纪音乐",
  "R&B": "节奏布鲁斯",
  "Danceable": "可跳舞的",
  "Bluegrass": "草莓乐",
  "Disco": "迪斯科",
  "Syncopated": "切分节奏",
  "Folk": "民谣",
  "Groovy": "悠扬",
  "Dance": "舞曲",
  "Tipsy": "微醺",
  "Afro-Cuban": "阿弗罗-古巴",
  "Dark": "黑暗",
  "Dance Pop": "流行舞曲",
  "Doom": "末日",
  "Dubstep": "配音步",
  "Dramatic": "戏剧性",
  "Disco Funk": "迪斯科放克",
  "Sinister": "阴险",
  "EDM": "电子舞曲",
  "Electronic": "电子",
  "Art": "艺术",
  "High-NRG": "高能量",
  "Nu": "新流行",
  "House": "洁室音乐",
  "Progressive": "进步",
  "Trance": "迷幻舞曲",
  "Hard": "强硬",
  "Downtempo": "缓拍",
  "Aggressive": "激进",
  "Banger": "热门曲目",
  "Drum'n'bass": "鼓与贝斯",
  "Power": "力量",
  "Stadium": "体育场",
  "Stomp": "重踏",
  "IDM": "智能舞曲",
  "Lyrical": "抒情的",
  "Synthpop": "合成流行",
  "Broadway": "百老汇",
  "Synthwave": "合成波",
  "Cabaret": "歌舞表演",
  "Operatic": "歌剧式的",
  "Jazz/Soul": "爵士/灵魂",
  "Storytelling": "讲故事",
  "Bebop": "比博普",
  "Lounge": "酒吧歌手",
  "Trap": "陷阱音乐",
  "Gospel": "福音",
  "Theatrical": "戏剧性的",
  "Troubadour": "吟游诗人",
  "Latin Jazz": "拉丁爵士",
  "Vegas": "拉斯维加斯风格",
  "RnB": "节奏蓝调",
  "Magical": "神奇",
  "Ethereal": "虚幻",
  "Majestic": "雄伟",
  "Bossa Nova": "波萨诺瓦",
  "Mysterious": "神秘",
  "Minimal": "简约",
  "Forró": "弗约罗",
  "Salsa": "萨尔萨舞",
  "Cinematic": "电影",
  "Tango": "探戈",
  "Slow": "缓慢",
  "Sparse": "稀疏",
  "Dancehall": "舞厅",
  "Party": "派对",
  "Dub": "配音",
  "Glam": "华丽",
  "Glitter": "闪耀",
  "Reggaeton": "雷盖顿",
  "Afrobeat": "非洲节奏",
  "Grooveout": "活力爆发",
  "Metal": "金属",
  "Soft": "柔和",
  "Deathcore": "死亡核",
  "Bedroom": "卧室",
  "Chillwave": "轻松浪潮",
  "Festive Heavy Metal": "节日重金属",
  "Intimate": "亲密",
  "Weird": "奇怪",
  "Power Metal": "力量金属",
  "Carnival": "嘉年华",
  "Haunted": "鬼屋",
  "Popular": "流行",
  "Random": "随机",
  "Musicbox": "音乐盒",
  "Hollow": "空洞",
  "Pop Rock": "流行摇滚",
  "World/Ethnic": "世界/民族",
  "Kpop": "韩流音乐",
  "Jpop": "日流音乐",
  "Rock": "摇滚",
  "Classic Rock": "经典摇滚",
  "Blues Rock": "布鲁斯摇滚",
  "Emo": "情绪",
  "Glam Rock": "华丽摇滚",
  "Indie": "独立音乐",
  "Industrial Rock": "工业摇滚",
  "Punk": "朋克摇滚",
  "Skate Rock": "滑板摇滚",
  "Skatecore": "滑板核",
  "Urban": "城市音乐",
  "Funk": "放克",
  "HipHop": "嘻哈",
  "Phonk": "酸音乐",
  "Rap": "说唱"
};

// 创建全局的 Audio 对象并挂载到 Vue 原型上
const app = createApp(App);
app.config.globalProperties.$globalAudio = new Audio();
app.config.globalProperties.$globalObject = globalObject;
app.config.globalProperties.$axios = axios; 
app.config.globalProperties.$globalUrl = globalUrl;
app.config.globalProperties.$genreMap = genreMap;
app.config.globalProperties.$genreMapReversed = genreMapReversed;



// 使用 router 和 vuetify 插件
app.use(router).use(vuetify);

// 挂载到 DOM
app.mount('#app');
